<template>
  <form @submit.prevent="save">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{label}} Bin</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-field label="Label">
          <b-input
            type="text"
            v-model="bin.label"
            placeholder="Bin Label"
            required />
        </b-field>
        <b-field label="Size">
          <b-select
            v-model="bin.sizeCode"
            placeholder="Select bin size"
            required
            expanded
          >
            <option
              v-for="(size, index) in binSizes"
              :key="index"
              :value="size.code"
            >
              {{ size.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Type">
          <b-select
            v-model="bin.typeCode"
            placeholder="Select bin type"
            required
            expanded
          >
            <option
              v-for="(type, index) in binTypes"
              :key="index"
              :value="type.code"
            >
              {{ type.label }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Service">
          <b-select
            v-model="bin.serviceCode"
            placeholder="Select bin service"
            required
            expanded
          >
            <option
              v-for="(service, index) in binServices"
              :key="index"
              :value="service.code"
            >
              {{ service.label }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!hasInfoChanges"
          >{{label}}</b-button
        >
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddEditBin',
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    binData: {
      type: Object,
      default: () => {
        return {
          label: '',
          sizeCode: null,
          typeCode: null,
          serviceCode: null
        }
      }
    }
  },
  methods: {
    save () {
      if (this.hasInfoChanges) {
        this.$emit('saveBin', {
          bin: this.bin,
          close: () => { this.$emit('close') }
        })
      }
    }
  },
  data () {
    return {
      bin: { ...this.binData }
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'binTypes',
      'binServices',
      'binSizes'
    ]),
    hasInfoChanges () {
      return JSON.stringify(this.binData) !== JSON.stringify(this.bin)
    },
    label () {
      return this.mode === 'add' ? 'Add' : 'Update'
    }
  }
}
</script>

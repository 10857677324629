<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Customer Information
    </hero-bar>
    <section class="section is-main-section">
      <b-progress v-if="isLoading" size="is-large" show-value>
        Fetching Customer Information
      </b-progress>
      <b-tabs type="is-boxed" v-else>
        <b-tab-item label="Details" icon="playlist-edit" class="is-success">
          <form @submit.prevent="submit">
            <div class="columns">
              <div class="column is-three-fifths">
                <b-field label="Customer Name" expanded>
                  <b-input
                    minlength="3"
                    v-model="customer.name"
                    name="customerName"
                    required
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Village">
                  <b-select
                    v-model="customer.villageCode"
                    placeholder="Select village"
                    required
                    expanded
                  >
                    <option
                      v-for="(village, index) in customerVillages"
                      :key="index"
                      :value="village.code"
                    >
                      {{ village.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Status">
                  <b-select
                    v-model="customer.statusCode"
                    placeholder="Select status"
                    required
                    expanded
                  >
                    <option
                      v-for="(status, index) in customerStatuses"
                      :key="index"
                      :value="status.code"
                    >
                      {{ status.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Address">
                  <b-input
                    minlength="3"
                    v-model="customer.address"
                    name="customerAddress"
                    required
                  />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Remarks">
                  <b-input
                    type="textarea"
                    v-model="customer.remarks"
                    name="customerRemarks"
                  />
                </b-field>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column">
                <b-field label="Contact Person">
                  <b-input
                    minlength="3"
                    v-model="customer.contactPerson"
                    name="customerContactPerson"
                    required
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Contact Number">
                  <b-input
                    minlength="3"
                    v-model="customer.contact"
                    name="customerContactNumber"
                    required
                  />
                </b-field>
              </div>
            </div>

            <card-component title="Bins" icon="trash-can">
              <b-table :data="bins" v-if="bins.length" hoverable>
                <b-table-column field="label" label="Label" v-slot="props">
                    {{ props.row.label }}
                </b-table-column>

                <b-table-column field="size" label="Size" v-slot="props">
                    {{ binSizesMapping[props.row.sizeCode] }}
                </b-table-column>

                <b-table-column field="type" label="Type" v-slot="props">
                    {{ binTypesMapping[props.row.typeCode] }}
                </b-table-column>

                <b-table-column field="service" label="Service" v-slot="props">
                    {{ binServicesMapping[props.row.serviceCode] }}
                </b-table-column>

                <b-table-column field="action" label="Action" v-slot="props" centered>
                    <b-field grouped position="is-centered">
                      <div class="control">
                        <b-button type="is-primary" size="is-small" @click="editBin(props.index)"
                          >Edit</b-button
                        >
                      </div>
                      <div class="control">
                        <b-button type="is-danger" size="is-small" @click="removeBin(props.index)"
                          >Delete</b-button
                        >
                      </div>
                    </b-field>
                </b-table-column>
              </b-table>

              <div class="content has-text-grey has-text-centered" v-else>
                <p>
                  <b-icon icon="playlist-remove" size="is-large" />
                </p>
                <p>No bin record. Click Add Bin to add a bin.</p>
              </div>

              <hr />
            </card-component>
            <b-field grouped position="is-right">
              <div class="control">
                <b-button @click="exit"
                  >Exit</b-button
                >
              </div>
              <div class="control" v-if="bins.length === 0">
                <b-button @click="deleteCustomer" type="is-danger"
                  >Delete</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="addBin"
                  >Add Bin</b-button
                >
              </div>
              <div class="control">
                <b-button native-type="submit" type="is-primary" :disabled="!infoHasChanges"
                  >Update</b-button
                >
              </div>
            </b-field>
          </form>
        </b-tab-item>
        <b-tab-item label="Records" icon="format-list-bulleted">
          <assigned-route-list :label="this.oldCustomerInfo.name" mode="customer"/>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customer from '@/services/customer'
import bin from '@/services/bin'

import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import AddEditBin from '@/modals/AddEditBin'
import AssignedRouteList from '@/components/AssignedRouteList'

export default {
  name: 'CustomerInfo',
  data () {
    return {
      id: null,
      isLoading: false,
      customer: {},
      bins: [],
      // used for checking if there is changes
      oldCustomerInfo: {}
    }
  },
  components: {
    TitleBar,
    HeroBar,
    CardComponent,
    AssignedRouteList
  },
  async mounted () {
    this.id = this.$route.params.id
    this.isLoading = true
    await this.loadCustomerInfo()
    this.isLoading = false
  },
  methods: {
    async loadCustomerInfo () {
      try {
        const customerInfo = await customer.getCustomer(this.id)
        this.oldCustomerInfo = { ...customerInfo }
        this.customer = { ...customerInfo }
        this.bins = this.customer.bins
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$router.push({ path: '/customers' })
      }
    },
    submit () {
      if (this.infoHasChanges) { this.updateCustomer() }
    },
    async updateCustomer () {
      try {
        const copyInfo = { ...this.customer }
        delete copyInfo.id
        delete copyInfo.bins
        delete copyInfo.timestamp_created
        delete copyInfo.timestamp_updated

        await customer.updateCustomer(this.id, copyInfo)
        this.oldCustomerInfo = { ...this.customer }
        this.$buefy.snackbar.open({
          message: 'Company updated successfully',
          queue: false
        })
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    addBin () {
      this.$buefy.modal.open({
        component: AddEditBin,
        parent: this,
        events: {
          saveBin: async (data) => {
            try {
              const newBin = await bin.addBin({
                customerId: this.id,
                ...data.bin
              })
              this.bins.push(newBin)
              this.$buefy.toast.open('Bin added successfully')
              data.close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    editBin (idx) {
      this.$buefy.modal.open({
        component: AddEditBin,
        parent: this,
        props: {
          mode: 'edit',
          binData: JSON.parse(JSON.stringify(this.bins[idx]))
        },
        events: {
          saveBin: async (data) => {
            try {
              await bin.updateBinById(data.bin.id, {
                customerId: data.bin.id,
                label: data.bin.label,
                serviceCode: data.bin.serviceCode,
                sizeCode: data.bin.sizeCode,
                typeCode: data.bin.typeCode
              })
              const tempCopy = this.bins
              tempCopy[idx] = data.bin
              this.bins = [...tempCopy]
              this.$buefy.toast.open('Bin updated successfully')
              data.close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    removeBin (idx) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove bin?',
        onConfirm: async () => {
          try {
            // if (this.bins.length === 1) {
            //   this.$buefy.snackbar.open({
            //     message: 'Customer must contain atleast 1 bin',
            //     queue: false
            //   })
            //   return
            // }

            await bin.deleteBin(this.bins[idx].id)
            const tempCopy = [...this.bins]
            tempCopy.splice(idx, 1)
            this.bins = tempCopy
            this.$buefy.toast.open('Bin removed successfully')
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    deleteCustomer () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to permanently delete customer?',
        onConfirm: async () => {
          try {
            await customer.deleteCustomerById(this.id)
            this.$buefy.snackbar.open({
              message: 'Company deleted successfully',
              queue: false
            })
            this.$router.go(-1)
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    exit () {
      // check if there is data added
      if (this.infoHasChanges) {
        this.$buefy.dialog.confirm({
          message: 'Changes will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'binTypesMapping',
      'binServicesMapping',
      'binSizesMapping',
      'customerStatuses',
      'customerVillages'
    ]),
    infoHasChanges () {
      return JSON.stringify(this.oldCustomerInfo) !== JSON.stringify(this.customer)
    },
    titleStack () {
      return ['Customers', 'View']
    }
  }
}
</script>

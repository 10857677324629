import bin from '@/api/bin'

export const addBin = (data) => {
  return bin.addBin(data)
}

export const updateBinById = (id, data) => {
  return bin.updateBinById(id, data)
}

export const deleteBin = (id) => {
  return bin.deleteBin(id)
}

export default {
  addBin,
  deleteBin,
  updateBinById
}
